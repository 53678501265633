<template>
  <div class="form-box">
     <head-layout head-title="随手拍" :head-btn-options="headBtnOptions" @head-save="headSave(false)" @head-saveBack="headSave(true)" @head-cancel="headCancel"></head-layout>
     <div class="formContentBox">
       <div class="formMain">
         <div style="padding: 0 12px">
           <el-form ref="dataForm" :model="dataForm" label-width="150px" :disabled="formType == 'view'" :rules="rules">
             <el-row>
               <el-col :span="8">
                 <el-form-item label="地点" prop="prjName">
                   <el-input  v-model="dataForm.location" placeholder="请填写地点">
                     <template slot="append">
                       <div class="el-icon-map-location" style="width: 54px;text-align: center" @click="openMap()"></div>
                     </template>
                   </el-input>
                 </el-form-item>
               </el-col>
               <el-col :span="8">
                 <el-form-item label="项目" prop="organizationName">
                   <el-input v-model="dataForm.organizationName" @focus="handleDeptModeOpen('organizationName')" :title="dataForm.organizationName"
                             placeholder="请填写项目"></el-input>
                 </el-form-item>
               </el-col>
             </el-row>
             <el-row>
               <el-col :span="8">
                 <el-form-item label="报告人" prop="reportUserName">
                   <el-input v-model="dataForm.reportUserName" @focus="handleUserModeOpen()"  placeholder="请选择报告人"></el-input>
                 </el-form-item>
               </el-col>
               <el-col :span="8">
                 <el-form-item label="报告部门" prop="reportDeptName">
                   <el-input v-model="dataForm.reportDeptName" @focus="handleDeptModeOpen('reportDeptName')" placeholder="请选择报告部门"></el-input>
                 </el-form-item>
               </el-col>
               <el-col :span="8">
                 <el-form-item label="报告时间" prop="reportDate">
                   <el-date-picker type="datetime" v-model="dataForm.reportDate" value-format="yyyy-MM-dd HH:mm:ss"  placeholder="请选择报告时间"></el-date-picker>
                 </el-form-item>
               </el-col>
             </el-row>
             <el-row>
               <el-col :span="24">
                 <el-form-item label="描述" prop="description">
                   <div class="flex-container">
                   <el-input type="textarea"  :autosize="{ minRows: 2}" show-word-limit v-model="dataForm.description" maxlength="225" placeholder="请填写描述" ></el-input>
                     <div class="hdDesc-mar">
                       <el-button type="primary" @click="handleDesc()">常见隐患</el-button>
                     </div>
                   </div>
                 </el-form-item>
               </el-col>
               <el-col :span="8">
                 <el-form-item label="业务类型" prop="shotType">
                   <avue-select :props="{
              label: 'dictValue',
              value: 'dictKey',
            }" v-model="dataForm.shotType" placeholder="请选择业务类型" type="tree" :dic="sourceDic"></avue-select>
                 </el-form-item>
               </el-col>
             </el-row>
             <el-row>
               <el-col :span="24">
                 <el-form-item label="现场" prop="treeData">
                   <el-upload
                     v-if="formType != 'view'"
                     accept=".jpg,.mp4,.png"
                     style="margin-bottom: 12px;display: flex; align-items: center;"
                     class="upload-demo"
                     action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km"
                     :show-file-list="false"
                     :before-upload="checkFileType"
                     :on-success="handleAvatarSuccess"
                     ref="uploadRef"
                     :headers="headers"
                   >
                     <el-button
                       size="small"
                       type="primary"
                       icon="el-icon-upload"
                     >点击上传
                     </el-button>
                   </el-upload>
                   <div class="content">
                     <div
                       v-for="(item, index) in treeData"
                       :key="item.id"
                       class="img-content"
                       @click="getNodeClick(item)"
                     >
                       <img :src="item.thumbnailLink" alt=""/>
                       <i
                         v-if="formType !== 'view'"
                         class="el-icon-circle-close delete-icon"
                         @click.stop="handleRemove(item, index)"
                       ></i>
                     </div>
                   </div>
                 </el-form-item>
               </el-col>
             </el-row>
           </el-form>
         </div>
       </div>
     </div>
    <!-- 地图 -->
    <el-dialog title="选择位置" append-to-body :visible.sync="mapBox" width="555px">
      <div class="import">
        <GaodeMap v-if="mapBox" ref="GaodeMap" @selectAddress="selectAddress" @getLocation="getLocation"></GaodeMap>
      </div>
      <span slot="footer">
        <el-button size="small" @click="mapBox = false">
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button size="small" type="primary" @click="handleMapSave">
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      title="附件预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="人员选择" :visible.sync="deptShow" width="80%" top="8vh">
      <UserDetpDialog ref="UserDetpDialog" @select-data="selectData"></UserDetpDialog>
    </el-dialog>
    <el-dialog :title="type == 'organizationName' ? '项目选择' : '部门选择'" :visible.sync="deptShow1" width="80%">
      <DeptDialog ref="DeptDialog" @select-data="selectData1"></DeptDialog>
    </el-dialog>
    <sortSetting ref="sortSetting" @callback="callback"></sortSetting>
  </div>
</template>
<script>
  import * as  API from "@/api/riskitemView/casually";
  import {getToken} from "@/util/auth";
  import website from "@/config/website";
  import {dateFormat} from "@/util/date";
  import {mapGetters} from "vuex";
  import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog"
  import DeptDialog from "@/views/components/UserDeptDialog/DeptDialog";
  import Template from "@/views/message/template/list.vue";
  import GaodeMap from "@/views/system/attendance/GaodeMap.vue";
  import sortSetting from "@/views/business/safetyTtandard/sortSetting/model.vue";
  export default {
    components: {
      sortSetting,
      GaodeMap,
      Template,
      UserDetpDialog,DeptDialog
    },
    data() {
      return {
        type:'',
        showFileDialog: false,
        deptShow: false,
        deptShow1: false,
        treeData:[],
        sourceDic:[],
        selectNode: {},
        formType: '',
        fileUrl: '',
        headTitle: '新增信息',
        mapBox: false,
        mapdata: '',
        dataForm: {
          location:'',
          reportDeptName:'',
          reportDate:dateFormat(new Date()),
        },
        corePersonnelTableOption: {
          index: true,
          indexLabel: "序号",
          menuWidth: 100,
          column: [{
              label: "检查内容",
              prop: "userName",
              align: "left",
              overHidden: true
            },
            {
              label: "检查的",
              prop: "birthday",
              width: 200,
              align: "center",
              overHidden: true,
              slot: true,
            },

          ],
        },
        tableData: [],
        tableLoading: false,
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        rules: {
          description: [{
            required: true,
            message: '请输入项目描述',
            trigger: 'blur'
          }],
          organizationName: [{
            required: true,
            message: '请选择项目',
            trigger: 'change'
          }],
          reportUserName: [{
            required: true,
            message: '请选择报告人',
            trigger: 'change'
          }],
          reportDate: [{
            required: true,
            message: '请选择报告时间',
            trigger: 'change'
          }],
          shotType: [{
            required: true,
            message: '请选择业务类型',
            trigger: 'change'
          }],
          treeData: [{
            required: true,
            message: '请选择现场图片',
            trigger: 'change'
          }],
        },
        selectionList: [],
      }
    },
    computed: {
      ...mapGetters([
        'userInfo',
      ]),
      headers: function () {
        return {"Sinoma-Auth": getToken()};
      },
      headBtnOptions() {
        let result = [];
        if (['add', 'edit'].includes(this.formType)) {
          result.push({
            label: "保存",
            emit: "head-save",
            type: "button",
            btnOptType: "save",
          });
          result.push({
            label: "提交并返回",
            emit: "head-saveBack",
            type: "button",
            btnOptType: "refer",
          });
        }
        result.push({
          label: "取消",
          emit: "head-cancel",
          type: "button",
          btnOptType: "cancel",
        });
        return result;
      },
    },
    mounted() {

      let type = this.$route.query.type;
      this.formType = type;
      if (type == 'edit') {
        this.dataForm.id = this.$route.query.id;
        this.getPrjbasicinfoDetail();
      }else if (type == 'view') {
        this.dataForm.id = this.$route.query.id;
        this.getPrjbasicinfoDetail();
      }else {
        if( this.userInfo.deptCategory == 5){
          this.$set(this.dataForm, 'organizationId', this.userInfo.dept_id)
          this.$set(this.dataForm, 'organizationName', this.userInfo.deptName)
        }
        this.$set(this.dataForm, 'reportDeptId', this.userInfo.dept_id)
        this.$set(this.dataForm, 'reportDeptName', this.userInfo.deptName)
        this.$set(this.dataForm, 'reportUser', this.userInfo.user_id)
        this.$set(this.dataForm, 'reportUserName', this.userInfo.real_name)
      }
      API.SHOT_TYPE('SHOT_TYPE').then(res=>{
        this.sourceDic=res.data.data
      })
    },
    methods: {
      handleDesc(){
        this.$refs.sortSetting.initTree()
      },
      callback(val){
        this.$set(this.dataForm,'description',val.content)
      },
      selectData(row){
        this.deptShow=false
        this.dataForm.reportUser=row.id
        this.dataForm.reportUserName=row.realName
        console.log("进行输出人员-------------------",row)
      },
      selectData1(row){
        if(this.type == 'organizationName'){
          this.$set(this.dataForm, 'organizationId', row.id)
          this.$set(this.dataForm, 'organizationName', row.deptName)
        }else {
          this.$set(this.dataForm, 'reportDeptId', row.id)
          this.$set(this.dataForm, 'reportDeptName', row.deptName)
        }
        this.deptShow1=false
        console.log("进行输出人员-------------------",row)
      },
      handleUserModeOpen(){
        this.deptShow=true
      },
      handleDeptModeOpen(type){
        this.type=type
        this.deptShow1=true
      },
      checkFileType(file){
        const fileType = file.name.substring(file.name.lastIndexOf('.'));
        const isLt20M = file.size / 1024 / 1024 < 20;
        const isLt5M = file.size / 1024 / 1024 < 5;
        const isJPG = (fileType === '.jpg' ||  fileType == '.png' || fileType == '.mp4');
        if (!isJPG) {
          this.$message.warning('上传图片只能是 .jpg  .png .mp4 格式!');
        }
        if ((fileType === '.jpg' ||  fileType == '.png' ) && !isLt5M) {
          this.$message.warning('上传图片大小不能超过 5MB!');
        }
        if (fileType == '.mp4' && !isLt20M) {
          this.$message.warning('上传文件大小不能超过 20MB!');
        }
        return isJPG && isLt20M && isLt5M;
      },
      handleAvatarSuccess(res, file, fileList) {
        this.treeData.push({
          extension: file.raw.type,
          id: file.response.data.attachId,
          link: file.response.data.link,
          thumbnailLink: file.response.data.thumbnailLink,
          name: file.response.data.originalName,
        });
        this.$set(this.dataForm,'treeData',this.treeData)
      },
      //获取经纬度
      openMap() {
        if(this.formType == 'view')return
        this.mapBox = true
      },
      // 高德地图用
      selectAddress(data) {
        this.$set(this.dataForm, 'location', data.address);
        this.$set(this.dataForm, 'latitude', data.lng + ',' + data.lat);
      },
      // 高德地图用
      getLocation(data) {
        this.$set(this.dataForm, 'location', data.address);
        this.$set(this.dataForm, 'latitude', data.lng + ',' + data.lat);
      },
      handleMapSave() {
        this.mapBox = false
      },
      headCancel() {
        this.$router.$avueRouter.closeTag();
        this.$router.back();
      },
      // 保存
      headSave(boolean) {
        // if (this.treeData.length == 0) {
        //   this.$message({
        //     type: "warning",
        //     message: "现场附件请上传",
        //   });
        //   return;
        // }
        this.$refs.dataForm.validate((valid) => {
          if (valid) {
            this.$loading();
            let url =this.dataForm.id ? API.update : API.save
            API.save({
              ...this.dataForm,
              hdStatus:boolean ? 'submit' : this.dataForm.hdStatus ? this.dataForm.hdStatus :'prepare' ,
              organizationId:this.userInfo.dept_id,
              shotUrl:JSON.stringify(this.treeData),
            }).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: !boolean ?"保存成功" :'提交成功',
                  type: "success",
                });
                if (boolean) {
                  this.$router.$avueRouter.closeTag();
                  this.$router.back();
                }else {
                  this.dataForm.id=res.data.data
                  this.getPrjbasicinfoDetail()

                }

                this.dataForm={
                  reportDate:dateFormat(new Date()),
                }
                this.$loading().close();
              }
            }).catch(err=>{
              this.$loading().close();
            })
          }
        });

      },
      // 根据ID查表单详情
      getPrjbasicinfoDetail() {
        API.detail({
          id: this.dataForm.id
        }).then((res) => {
          if (res.data.code == 200) {
            this.dataForm = res.data.data;
            if(res.data.data.shotUrl)
            this.treeData=JSON.parse(res.data.data.shotUrl)
            this.$set(this.dataForm,'treeData',this.treeData)
          }
        })
      },
      getNodeClick(node) {
        this.selectNode = node;
        this.showFile(node);
      },
      handleRemove(item, index) {
        this.treeData.splice(index, 1);
      },
      showFile(item) {
        this.fileUrl =
          process.env.VUE_APP_BASE_KKFILEURL+"?url=" +
          encodeURIComponent(Base64.encode(item.link));
        this.showFileDialog = true;
        //exls表格预览隐藏打印按钮
        if (process.env.NODE_ENV === "production") {
          const iframe = this.$refs.fileIframe;
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;

          // 在 iframe 内部隐藏元素
          if (iframeDocument) {
            const elementToHide = iframeDocument.getElementById("button-area");
            if (elementToHide) {
              elementToHide.style.display = "none";
            }
          }
        }
      },
    }
  }

</script>
<style lang="scss" >
.form-box{
  background-color: #ffffff;


  .formContentBox {
    padding: 12px;
    height: calc(100% - 76px);
  }
  .el-input-group__append{
    padding: 0;
  }
  .formMain {
    width: 100%;
    overflow: auto;
    height: 100%;
  }
  .step-box{
    background-color: #ffffff;
    width: 125px;
  }

  .formTopic {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid rgb(187, 187, 187);
    font-weight: 600 !important;
    font-size: 18px;
  }

  .shuttleBackBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;

    .el-tabs {
      width: 100%;
    }

    .table-box {
      width: calc(100% - 280px);
    }
  }

  .shutleBack {
    width: 33%;
    background: #ffffff;
    border: 1px solid #ebeef5;
    border-radius: 4px;
  }

  .leftRightBtn {
    margin: 211px 12px;
  }

  .shutleTitle {
    width: 100%;
    background-color: #f5f7fa;
    height: 40px;
    line-height: 40px;
    text-indent: 12px;
    border-bottom: 1px solid #ebeef5;
    font-size: 16px;
  }

  .shutContent {
    padding: 6px;
    height: 400px;
    overflow: auto;
  }

  .deptBox {
    line-height: 40px;
    color: #333333;
    border-bottom: 1px solid #ebeef5;
    font-size: 13px;
    display: flex;
  }

  .unitBox {
    width: 100%;
    display: flex;
    background-color: #f5f7fa;
    border-bottom: 1px solid #ebeef5;
  }

  .unitTitle {
    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    font-size: 16px;
    cursor: pointer;
    border-right: 1px solid #ebeef5;
  }
  .content {
    display: flex;
    align-items: center;
    .img-content {
      width: 188px;
      height: 100px;
      padding: 3px;
      position: relative;
      border: 1px solid #ccc;
      border-radius: 3px;
      margin-right: 12px;
      .delete-icon {
        position: absolute;
        top: -6px;
        right: -6px;
        font-size: 16px;
        color: red;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

  }
}
</style>
