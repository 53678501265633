var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-box" },
    [
      _c("head-layout", {
        attrs: {
          "head-title": "随手拍",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave(false)
          },
          "head-saveBack": function ($event) {
            return _vm.headSave(true)
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("div", { staticClass: "formContentBox" }, [
        _c("div", { staticClass: "formMain" }, [
          _c(
            "div",
            { staticStyle: { padding: "0 12px" } },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  attrs: {
                    model: _vm.dataForm,
                    "label-width": "150px",
                    disabled: _vm.formType == "view",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "地点", prop: "prjName" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { placeholder: "请填写地点" },
                                  model: {
                                    value: _vm.dataForm.location,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "location", $$v)
                                    },
                                    expression: "dataForm.location",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _c("div", {
                                      staticClass: "el-icon-map-location",
                                      staticStyle: {
                                        width: "54px",
                                        "text-align": "center",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openMap()
                                        },
                                      },
                                    }),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "项目",
                                prop: "organizationName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  title: _vm.dataForm.organizationName,
                                  placeholder: "请填写项目",
                                },
                                on: {
                                  focus: function ($event) {
                                    return _vm.handleDeptModeOpen(
                                      "organizationName"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.dataForm.organizationName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "organizationName",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.organizationName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "报告人",
                                prop: "reportUserName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请选择报告人" },
                                on: {
                                  focus: function ($event) {
                                    return _vm.handleUserModeOpen()
                                  },
                                },
                                model: {
                                  value: _vm.dataForm.reportUserName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "reportUserName",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.reportUserName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "报告部门",
                                prop: "reportDeptName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请选择报告部门" },
                                on: {
                                  focus: function ($event) {
                                    return _vm.handleDeptModeOpen(
                                      "reportDeptName"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.dataForm.reportDeptName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "reportDeptName",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.reportDeptName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "报告时间", prop: "reportDate" },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetime",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  placeholder: "请选择报告时间",
                                },
                                model: {
                                  value: _vm.dataForm.reportDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "reportDate", $$v)
                                  },
                                  expression: "dataForm.reportDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "描述", prop: "description" } },
                            [
                              _c(
                                "div",
                                { staticClass: "flex-container" },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      autosize: { minRows: 2 },
                                      "show-word-limit": "",
                                      maxlength: "225",
                                      placeholder: "请填写描述",
                                    },
                                    model: {
                                      value: _vm.dataForm.description,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.description",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "hdDesc-mar" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDesc()
                                            },
                                          },
                                        },
                                        [_vm._v("常见隐患")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "业务类型", prop: "shotType" } },
                            [
                              _c("avue-select", {
                                attrs: {
                                  props: {
                                    label: "dictValue",
                                    value: "dictKey",
                                  },
                                  placeholder: "请选择业务类型",
                                  type: "tree",
                                  dic: _vm.sourceDic,
                                },
                                model: {
                                  value: _vm.dataForm.shotType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "shotType", $$v)
                                  },
                                  expression: "dataForm.shotType",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "现场", prop: "treeData" } },
                            [
                              _vm.formType != "view"
                                ? _c(
                                    "el-upload",
                                    {
                                      ref: "uploadRef",
                                      staticClass: "upload-demo",
                                      staticStyle: {
                                        "margin-bottom": "12px",
                                        display: "flex",
                                        "align-items": "center",
                                      },
                                      attrs: {
                                        accept: ".jpg,.mp4,.png",
                                        action:
                                          "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km",
                                        "show-file-list": false,
                                        "before-upload": _vm.checkFileType,
                                        "on-success": _vm.handleAvatarSuccess,
                                        headers: _vm.headers,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                            icon: "el-icon-upload",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "点击上传\n                   "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "content" },
                                _vm._l(_vm.treeData, function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.id,
                                      staticClass: "img-content",
                                      on: {
                                        click: function ($event) {
                                          return _vm.getNodeClick(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: item.thumbnailLink,
                                          alt: "",
                                        },
                                      }),
                                      _vm.formType !== "view"
                                        ? _c("i", {
                                            staticClass:
                                              "el-icon-circle-close delete-icon",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.handleRemove(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择位置",
            "append-to-body": "",
            visible: _vm.mapBox,
            width: "555px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.mapBox = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "import" },
            [
              _vm.mapBox
                ? _c("GaodeMap", {
                    ref: "GaodeMap",
                    on: {
                      selectAddress: _vm.selectAddress,
                      getLocation: _vm.getLocation,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.mapBox = false
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleMapSave },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "附件预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showFileDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFileDialog = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "fileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.fileUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFileDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.deptShow,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            on: { "select-data": _vm.selectData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.type == "organizationName" ? "项目选择" : "部门选择",
            visible: _vm.deptShow1,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow1 = $event
            },
          },
        },
        [
          _c("DeptDialog", {
            ref: "DeptDialog",
            on: { "select-data": _vm.selectData1 },
          }),
        ],
        1
      ),
      _c("sortSetting", { ref: "sortSetting", on: { callback: _vm.callback } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }